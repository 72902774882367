import React from "react"
import styled from 'styled-components'
import { graphql } from 'gatsby'

import Layout from '../../layout'

import BackgroundContainer from '../../components/image/background-container'
import TitleContainer from '../../components/common/title-container'
import Title from '../../components/common/title'
import Subtitle from '../../components/common/subtitle'
import PageContainer from '../../components/common/page-container'

export default (props) => (
    <Layout title="Events">
        <BackgroundContainer header={props.data.header}>
            <TitleContainer>
                <Title>Events</Title>
                <Subtitle>Our speaking engagements</Subtitle>
            </TitleContainer>
        </BackgroundContainer>

        <PageContainer>
            <p>Our leadership team regularly present at events, conferences and user-groups about technical topics, and we regularly attend technology events.</p>
            <h2>Upcoming speaking engagements</h2>
            <ul>
                <li><a href="https://www.meetup.com/phpnortheast/events/263025862/">Collections in Laravel, PHP North East, July 2019 – Nathan Dunn, Innovation Director at packagedby</a></li>
            </ul>
            <h2>Previous speaking engagements</h2>
            <ul>
                <li>Alexa, let's make a skill – ConFoo, Montreal, Canada</li>
                <li>API Development with Laravel – ConFoo, Montreal, Canada</li>
                <li>Introduction to Unit Testing – School of Tech,, Newcastle, UK</li>
                <li>Laravel 5 Workshop – DIBI Conference, Newcastle, UK</li>
                <li>Refactoring to Symfony Components – ConFoo, Montreal, Canada</li>
                <li>Flexible payments with Stripe – ConFoo, Montreal, Canada</li>
                <li>Introduction to iOS Development – ConFoo, Montreal, Canada</li>
                <li>Team Development with Vagrant – ConFoo, Montreal, Canada</li>
                <li>"Frameworks" closing keynote panel – PHP UK Conference, London, UK</li>
                <li>Refactoring to Symfony Components – PHP UK Conference, London, UK</li>
                <li>Evolution of a "big data" project – Dutch PHP Conference, Amsterdam</li>
                <li>Real time voice call integration – ConFoo, Montreal, Canada</li>
                <li>Dealing with continuous data processing – ConFoo, Montreal, Canada</li>
                <li>Data at Scale – Cloud Connect, Santa Clara, California, USA</li>
                <li>Abstracting functionality with centralised content – PHP North West Conference, Manchester, UK</li>
            </ul>
        </PageContainer>
    </Layout >
)

export const query = graphql`
  query {
    header: file(relativePath: { eq: "about.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
